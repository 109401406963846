<template>
  <transition name="slide">
    <div v-if="user">
      <b-alert show :variant="variant" class="p-1 mb-0">
        <b-media vertical-align="center">
          <template #aside>
            <b-link @click="openUserModal(user)">
              <b-avatar
                size="80"
                :src="user.image ? user.image.path || user.image || '' : ''"
                :variant="variant"
                :text="avatarText(user.name)"
                class="cursor-pointer"
              />
            </b-link>
          </template>
          <b-link
            class="font-weight-bold d-block text-nowrap"
            :to="userType ? `/${userType}/${user.uuid}` : '#'"
            :target="userType ? '_blank' : ''"
            @click="openUserModal(user, userType)"
          >
            <feather-icon v-if="userType" icon="ExternalLinkIcon" size="12" />
            {{ user.name }}
          </b-link>
          <div class="d-flex flex-column">
            <!-- Email  -->
            <small v-if="user.email" class="text-muted">
              <feather-icon icon="MailIcon" size="12" />
              {{ user.email }}
            </small>

            <!-- Phone  -->
            <small v-if="user.mobile_number" class="text-muted">
              <feather-icon icon="PhoneIcon" size="12" />
              {{ user.mobile_number }}
            </small>

            <!-- Address -->
            <small v-if="user.address" class="text-muted">
              <feather-icon icon="MapPinIcon" size="12" />
              {{ user.address }}
              (
              {{
                isRTL
                  ? `${user.country.name_ar} ${
                      user.governorate ? "," + user.governorate.name_ar : ""
                    } ${user.area ? "," + user.area.name_ar : ""}`
                  : `${user.country.name || ""} ${
                      user.governorate ? "," + user.governorate.name : ""
                    } ${user.area ? "," + user.area.name : ""}`
              }})
            </small>

            <!-- notes -->
            <small v-if="user.notes" class="text-muted">
              <feather-icon icon="AlertCircleIcon" size="12" />
              {{ user.notes }}
            </small>

            <small v-if="user" class="text-muted">
              <b-link
                class="font-weight-bold d-block text-nowrap"
                @click="openUserModal(user)"
              >
                {{ $t("more_info") }}...
              </b-link>
            </small>
            <div v-if="displaySelect" class="my-1 border p-1 rounded">
              <AppSelect
                id="select_box"
                v-model="selectedOption"
                :options="selectOptions"
                :placeholder="selectLabel"
                :field-label="selectLabel"
                :clearable="selectClearable"
                @input="onSelect"
              />
            </div>
            <div class="d-flex">
              <AppButton
                :variant="variant"
                class="p-50 mt-50"
                :label="userComityHandler"
                @click="addUserToComity(user)"
              />
            </div>
          </div>
        </b-media>
      </b-alert>

      <UserInfoModal
        :id="`user-info-modal-${user.uuid}`"
        :user-type="userType"
        :user-data="user"
      />
    </div>
  </transition>
</template>

<script>
import { BAvatar, BAlert, BMedia, BLink } from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import UserInfoModal from "./UserInfoModal.vue";
import { AppButton, AppSelect } from "@/components/form";

export default {
  components: {
    BAvatar,
    AppButton,
    BAlert,
    BMedia,
    BLink,
    UserInfoModal,
    AppSelect,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    userType: {
      type: String,
      required: false,
      default: null,
    },
    comity: {
      type: Object,
      required: false,
      default: null,
    },
    variant: {
      type: String,
      required: false,
      default: "primary",
    },
    displaySelect: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    selectLabel: {
      type: String,
      required: false,
      default: "",
    },
    selectedValue: {
      type: Object,
      required: false,
      default: null,
    },
    selectClearable: {
      type: Boolean,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      selectedOption: this.selectedValue,
    };
  },
  mounted() {
    this.selectedOption = this.selectedValue;
  },
  computed: {
    isRTL() {
      return window.localStorage.getItem("language") === "ar";
    },
    userComityHandler() {
      if (!this.user || !this.comity) return this.$t("add_to_comity");

      return this.comity
        ? this.comity.users.find((user) => user.uuid === this.user.uuid)
          ? this.$t("remove_from_comity")
          : this.$t("add_to_comity")
        : this.$t("add_to_comity");
    },
  },
  methods: {
    avatarText,
    openUserModal(user, userType) {
      if (userType || !user) return;
      this.$bvModal.show(`user-info-modal-${user.uuid}`);
    },
    addUserToComity(user) {
      this.$emit("addUserToComity", user);
    },
    onSelect(value) {
      this.$emit("onSelect", value, this.user);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
