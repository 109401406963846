<template>
  <transition name="slide">
    <div>
      <div class="row">
        <AppSelect
          id="users-search"
          v-model="selected_search_by"
          class="col-4"
          :options="search_by_options"
          :placeholder="$t('search_by')"
          :field-label="$t('search_by')"
        />
        <AppSelect
          id="user_select"
          ref="user_select"
          v-model="selected_user"
          class="col-8"
          :placeholder="$t('select_user')"
          :field-label="$t('user')"
          :label="$t('user')"
          :options="dataArr"
          :class="required ? 'required' : ''"
          :rules="required ? 'required' : ''"
          v-bind="$attrs"
          store-module="judges"
          :search-key="selected_search_by ? selected_search_by.value : 'name'"
          :search-store-action="action"
          v-on="$listeners"
          @input="addJudgeToList"
        />
      </div>
      <transition name="slide">
        <section v-if="users && users.length">
          <hr />
          <h5>{{ $t("comity_users") }}</h5>

          <b-list-group>
            <b-list-group-item
              v-for="(user, index) in users"
              :key="index"
              class="p-0"
            >
              <transition name="slide">
                <div v-if="user">
                  <UserCard
                    :user="user"
                    :comity="{ users }"
                    variant="primary"
                    :display-select="awardType === 'individual'"
                    :select-options="departments"
                    :select-label="$t('select_department')"
                    :selected-value="getUserDepartment(user)"
                    :select-clearable="false"
                    @addUserToComity="assignJudge"
                    @onSelect="onSelectDepartment"
                  />
                </div>
              </transition>
            </b-list-group-item>
          </b-list-group>
        </section>
      </transition>
    </div>
  </transition>
</template>

<script>
import { BListGroup, BListGroupItem } from "bootstrap-vue";
// import userCan from "@/mixins/UserCan";
import UserCard from "@/components/UI/user/UserCard.vue";
import { AppSelect } from "@/components/form/index";

export default {
  name: "UsersSelect",
  components: {
    AppSelect,
    UserCard,
    BListGroup,
    BListGroupItem,
  },
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    awardType: {
      type: String,
      required: false,
      default: "",
    },
    users: {
      type: Array,
      required: false,
      default: null,
    },
    action: {
      type: String,
      required: false,
      default: null,
    },
    dataArr: {
      type: Array,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      selected_search_by: null,
      selected_user: null,
      search_by_options: [
        { value: "name", label: this.$t("name") },
        { value: "email", label: this.$t("email") },
        {
          value: "mobile_number",
          label: this.$t("mobile_number"),
        },
      ],
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.judges.isLoading;
    },
    isRTL() {
      return window.localStorage.getItem("language") === "ar";
    },
    departments() {
      const allDepartmentsOptions = this.$store.state.departments.data?.map(
        (department) => {
          return {
            value: department.uuid,
            label: this.isRTL
              ? department.name_ar || department.name
              : department.name || department.name_ar,
            name_ar: department.name_ar,
          };
        }
      );
      return allDepartmentsOptions || [];
    },
  },
  methods: {
    getUserDepartment(user) {
      // console.log(
      //   "🚀 ~ file: UsersSelect.vue ~ line 143 ~ getUserDepartment ~ user",
      //   user
      // );
      return this.departments.find(
        (department) => department.value === user.department
      );
    },
    addJudgeToList(user) {
      if (!user) return;
      if (this.users.find((judge) => judge.id === user.id)) return;
      this.assignJudge(user);
    },
    assignJudge(item) {
      this.$emit("assignJudge", item);
    },
    onSelectDepartment(item, user) {
      this.$emit("onSelectDepartment", item, user);
    },
  },
};
</script>
