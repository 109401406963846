<template>
  <div>
    <Filters @filterDocs="fetchAwardsHandler" />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <!-- Search -->
      <div
        class="
          p-2
          d-flex
          align-items-center
          justify-content-md-between
          flex-column-reverse flex-md-row
        "
      >
        <div>
          <transition name="fade">
            <span v-if="selectedRows && selectedRows.length" class="d-flex">
              <AppButton
                variant="primary"
                class="mr-2"
                data-test="unselect-all-btn"
                @click="unselectAllRowsHandler"
              >
                <span class="text-nowrap">
                  {{ $t("unselect_all") }}
                </span>
              </AppButton>
              <AppButton
                variant="danger"
                data-test="delete-selected-btn"
                @click="deletionWarningHandler(selectedRows)"
              >
                <span class="text-nowrap">
                  {{ $t("delete_all_selected") }}
                </span>
              </AppButton>
            </span>
          </transition>
        </div>
        <div class="d-flex flex-column flex-md-row mb-2 mb-md-0">
          <AppInput
            v-model="searchQuery"
            data-test="search-input"
            class="search-input mb-2 mb-md-0 d-inline-block w-auto"
            :placeholder="$t('search_by_name')"
            autocomplete="off"
            group-classes="mb-0"
            @input="searchDocsHandler"
          />
          <b-link
            v-if="userCan('awards-add')"
            data-test="add-new-btn"
            class="btn btn-primary mx-1 mb-2 mb-md-0"
            to="/add-new-award"
          >
            <span class="text-nowrap">
              {{ $t("add_new_award") }}
            </span>
          </b-link>
        </div>
      </div>
      <b-overlay :show="isLoading || isDeleting" rounded>
        <b-table
          ref="table"
          class="position-relative"
          :items="dataArray"
          responsive
          hover
          striped
          :fields="fields"
          show-empty
          :empty-text="$t('no_matching_records_found')"
        >
          <template #cell(checkbox)="data">
            <span class="align-text-top text-capitalize">
              <b-form-checkbox
                :value="data.item.uuid"
                :checked="
                  selectedRows &&
                  selectedRows.length &&
                  selectedRows.find((e) => e === data.item.uuid)
                "
                class="align-text-top"
                @change="rowSelectedHandler($event, data.item.uuid)"
              ></b-form-checkbox>
            </span>
          </template>
          <template #cell(image)="data">
            <img
              v-if="data.item.image && data.item.image.path"
              data-test="image"
              height="100"
              width="100"
              class="object-fit-contain mb-2"
              :src="data.item.image.path"
              :alt="data.item.image.alt_ar || ''"
            />
          </template>
          <template #cell(title)="data">
            <span class="align-text-top text-capitalize">
              {{ data.item.title }}
            </span>
          </template>
          <template #cell(type)="data">
            <span v-if="data.item.type" class="align-text-top text-capitalize">
              {{ $t(data.item.type) || data.item.type }}
            </span>
          </template>
          <template #cell(kpis)="data">
            <ul v-if="data.item.kpis && data.item.kpis.length">
              <li
                v-for="(kpi, index) in data.item.kpis"
                :key="index"
                variant="primary"
                pill
                class="align-text-top mb-50 mr-50 text-nowrap"
              >
                {{ kpi.name_ar }} ({{ $t("max_score") }}: {{ kpi.max_score }})
              </li>
            </ul>
            <span v-else>
              <span class="align-text-top">
                {{ $t("no_kpis") }}
              </span>
            </span>
          </template>

          <template #cell(technical_judges)="data">
            <span
              v-if="
                data.item.technical_judges && data.item.technical_judges.length
              "
            >
              <b-media
                v-for="(judge, index) in data.item.technical_judges"
                :key="index"
                vertical-align="center"
              >
                <template #aside>
                  <b-avatar
                    size="32"
                    :src="typeof judge.image === 'string' ? judge.image : null"
                    :to="`/user/${judge.uuid}`"
                    variant="primary"
                    :text="avatarText(judge.name)"
                    target="blank"
                  />
                </template>
                <b-link
                  :to="`/user/${judge.uuid}`"
                  target="blank"
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ judge.name }}
                </b-link>
                <small class="text-muted">{{ judge.email }}</small>
              </b-media>
            </span>
            <span v-else>
              <span class="align-text-top">
                {{ $t("no_technical_judges") || "No Judges" }}
              </span>
            </span>
          </template>

          <template #cell(judges)="data">
            <span v-if="data.item.judges && data.item.judges.length">
              <b-media
                v-for="(judge, index) in data.item.judges"
                :key="index"
                vertical-align="center"
              >
                <template #aside>
                  <b-avatar
                    size="32"
                    :src="typeof judge.image === 'string' ? judge.image : null"
                    :to="`/user/${judge.uuid}`"
                    variant="primary"
                    :text="avatarText(judge.name)"
                    target="blank"
                  />
                </template>
                <b-link
                  :to="`/user/${judge.uuid}`"
                  target="blank"
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ judge.name }}
                </b-link>
                <small class="text-muted">{{ judge.email }}</small>
              </b-media>
            </span>
            <span v-else>
              <span class="align-text-top">
                {{ $t("no_judges") || "No Judges" }}
              </span>
            </span>
          </template>

          <template #cell(in_home)="data">
            <span class="align-text-top text-capitalize">
              {{
                data.item.in_home == "1"
                  ? $t("displayed_in_home_page")
                  : $t("not_display_in_home_page")
              }}
            </span>
          </template>

          <template #cell(created_at)="data">
            <span
              v-if="data.item.created_at"
              class="align-text-top text-capitalize"
            >
              {{ formatDate(data.item.created_at) }}
            </span>
          </template>
          <template #cell(edit_delete)="data">
            <div class="d-flex flex-column actions">
              <AppButton
                v-if="userCan('awards-assign_judges')"
                variant="primary"
                class="mb-1 text-nowrap"
                @click="assignNonTechJudgesHandler(data.item)"
              >
                {{ $t("assign_judges") }}
              </AppButton>

              <AppButton
                v-if="userCan('awards-assign_judges')"
                variant="primary"
                class="mb-1 text-nowrap"
                @click="assignTechJudgesHandler(data.item)"
              >
                {{ $t("assign_technical_judges") }}
              </AppButton>

              <AppButton
                v-if="userCan('awards-assign_kpis')"
                variant="primary"
                class="mb-1 text-nowrap"
                @click="assignKpisHandler(data.item)"
              >
                {{ $t("assign_kpis") }}
              </AppButton>

              <AppButton
                v-if="userCan('awards-edit')"
                variant="primary"
                class="mb-1"
                @click="goToEditHandler(data.item.uuid)"
              >
                {{ $t("edit") }}
              </AppButton>
              <AppButton
                v-if="userCan('awards-delete')"
                variant="danger"
                @click="deletionWarningHandler(data.item)"
              >
                {{ $t("delete") }}
              </AppButton>
            </div>
          </template>
        </b-table>

        <!-- pagination -->
        <div v-if="dataArray && dataArrayMeta.total" class="m-2">
          <b-row>
            <b-col
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-start
              "
            >
              <span v-if="dataArrayMeta" class="text-muted">
                {{ $t("total_entries", { total: dataArrayMeta.total }) }}
              </span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="
                d-flex
                align-items-center
                justify-content-center justify-content-sm-end
              "
            >
              <b-pagination
                v-if="dataArrayMeta && dataArrayMeta.total"
                v-model="dataArrayMeta.current_page"
                :total-rows="dataArrayMeta.total"
                :per-page="dataArrayMeta.per_page"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @change="changePaginationHandler"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>

    <Warning
      id="delete-warning-modal"
      :is-loading="isDeleting"
      :title="$t('delete_selected')"
      :payload="itemsToBeDeleted"
      @cancel="cancelDeletionHandler"
      @approve="deleteHandler"
    />

    <AppForm
      id="assign_non_tech_judges_modal"
      :title="$t('assign_judges')"
      :cancel-label="$t('cancel')"
      :submit-label="$t('save')"
      :is-loading="isLoadingJudges || isLoading"
      :overlay-opacity="1"
      :variant="'primary'"
      :errors-bag="nonTechJudgesErrors"
      @submit.prevent="submitNonTechJudgesHandler"
      @show="fetchNonTechJudges"
      @hide="resetAssignJudgesModal"
    >
      <UsersSelect
        :users="selectedNonTechJudges"
        action="judges/fetchNonTechnicalJudges"
        :data-arr="nonTechJudges"
        @assignJudge="addNonTechJudgeToAward"
      />
    </AppForm>

    <AppForm
      id="assign_tech_judges_modal"
      :title="$t('assign_tech_judges')"
      :cancel-label="$t('cancel')"
      :submit-label="$t('save')"
      :is-loading="isLoadingJudges || isLoading"
      :overlay-opacity="1"
      :variant="'primary'"
      :errors-bag="techJudgesErrors"
      @submit.prevent="submitTechJudgesHandler"
      @show="fetchTechJudges"
      @hide="resetAssignJudgesModal"
    >
      <UsersSelect
        :award-type="itemPreviewed ? itemPreviewed.type : ''"
        :users="selectedTechJudges"
        action="judges/fetchTechnicalJudges"
        :data-arr="technicalJudges"
        @assignJudge="addTechJudgeToAward"
        @onSelectDepartment="onSelectDepartment"
      />
    </AppForm>

    <AppForm
      id="assign_kpis_modal"
      :title="$t('assign_kpis')"
      :cancel-label="$t('cancel')"
      :submit-label="$t('save')"
      :is-loading="isLoadingKpis"
      :overlay-opacity="1"
      :variant="'primary'"
      :errors-bag="kpisErrors"
      @submit.prevent="submitKpisHandler"
      @show="fetchAllKpis"
      @hide="resetAssignKpisModal"
    >
      <AppSelect
        id="kpis-select"
        v-model="selectedKpis"
        data-test="kpis"
        :placeholder="$t('select_kpis')"
        :field-label="$t('kpis')"
        class="choose-kpis-handler"
        :label="$t('kpis')"
        multiple
        search-store-action="kpi/fetchAllData"
        :options="kpis"
      />

      <hr />
      <section>
        <div v-for="(kpi, index) in selectedKpis" :key="index">
          <div class="row align-items-center">
            <span class="col-6"> {{ kpi.name_ar }} </span>
            <AppInput
              v-model="kpi['max_score']"
              :data-test="`kpi_max_score-input-${index}`"
              type="number"
              class="col-6 mb-2 mb-md-0 d-inline-block w-auto"
              :placeholder="$t('max_score')"
              group-classes="mb-0"
            />
          </div>
          <hr />
        </div>
      </section>
    </AppForm>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BOverlay,
  BPagination,
  BFormCheckbox,
  BBadge,
  BLink,
  BMedia,
  BAvatar,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import {
  AppButton,
  AppInput,
  AppSelect,
  AppForm,
} from "@/components/form/index";

import Filters from "./partials/filters.vue";
import userCan from "@/mixins/UserCan";
import UsersSelect from "./UsersSelect.vue";

export default {
  name: "Awards",
  components: {
    BRow,
    BCol,
    BLink,
    BFormCheckbox,
    UsersSelect,
    BCard,
    BTable,
    BOverlay,
    AppButton,
    AppInput,
    BPagination,
    Filters,
    BBadge,
    AppSelect,
    AppForm,
    BMedia,
    BAvatar,
  },
  data() {
    return {
      Document: null,
      selectedKpis: [],
      fields: [
        {
          key: "checkbox",
          label: this.$t("select") || "Select",
        },
        { key: "edit_delete", label: this.$t("edit_delete") || "Edit/Delete" },
        {
          key: "name_ar",
          label: this.$t("name_ar") || "name_ar",
        },
        {
          key: "brief_description_ar",
          label: this.$t("brief_description_ar") || "brief_description_ar",
        },
        {
          key: "in_home",
          label: this.$t("in_home") || "in_home",
        },
        { key: "kpis", label: this.$t("kpis") || "kpis" },
        { key: "judges", label: this.$t("judges") || "judges" },
        {
          key: "technical_judges",
          label: this.$t("technical_judges") || "technical_judges",
        },
        {
          key: "type",
          label: this.$t("type") || "type",
        },
        {
          key: "created_at",
          label: this.$t("created_at") || "Created at",
        },
      ],
      appliedFilters: {},
      searchQuery: "",
      searchQueryTimer: null,
      selectedRows: [],
      itemPreviewed: null,
      itemsToBeDeleted: [],
      selectedNonTechJudges: [],
      selectedTechJudges: [],
    };
  },
  computed: {
    isRTL() {
      return window.localStorage.getItem("language") === "ar";
    },
    dataArray() {
      return this.$store.state.awards.data;
    },
    dataArrayMeta() {
      return this.$store.state.awards.meta;
    },
    errors() {
      return this.$store.state.awards.errors;
    },
    nonTechJudgesErrors() {
      return this.$store.state.awards.nonTechJudgesErrors;
    },
    techJudgesErrors() {
      return this.$store.state.awards.techJudgesErrors;
    },
    kpisErrors() {
      return this.$store.state.awards.kpisErrors;
    },
    isLoading() {
      return this.$store.state.awards.isLoading.fetching;
    },
    isDeleting() {
      return this.$store.state.awards.isLoading.deleting;
    },
    kpis() {
      return this.$store.state.kpi.data;
    },
    isLoadingKpis() {
      return this.$store.state.kpi.isLoading.fetching;
    },
    nonTechJudges() {
      return this.$store.state.judges.nonTechJudges;
    },
    technicalJudges() {
      return this.$store.state.judges.techJudges;
    },
    isLoadingJudges() {
      return this.$store.state.judges.isLoading.fetching;
    },
  },
  created() {
    this.fetchAwardsHandler();
    this.fetchAllKpis();
    this.fetchNonTechJudges();
    this.fetchTechJudges();
    this.$store.dispatch("departments/fetchAllData");
  },
  mounted() {},
  methods: {
    userCan,
    avatarText,
    onSelectDepartment(department, user) {
      if (!department || !user) return;

      this.selectedTechJudges = this.selectedTechJudges.map((judge) => {
        if (judge.uuid === user.uuid) return { ...user, department };

        return judge;
      });
    },
    fetchNonTechJudges() {
      this.$store.dispatch("judges/fetchNonTechnicalJudges");
    },
    fetchTechJudges() {
      this.$store.dispatch("judges/fetchTechnicalJudges");
    },
    assignKpisHandler(item) {
      this.itemPreviewed = item;
      this.$bvModal.show("assign_kpis_modal");
    },
    resetAssignKpisModal() {
      this.itemPreviewed = null;
      this.selectedKpis = [];
    },
    assignNonTechJudgesHandler(item) {
      this.itemPreviewed = item;
      this.selectedNonTechJudges =
        JSON.parse(JSON.stringify(item.judges)) || [];
      this.$bvModal.show("assign_non_tech_judges_modal");
    },
    assignTechJudgesHandler(item) {
      this.itemPreviewed = item;
      this.selectedTechJudges =
        JSON.parse(JSON.stringify(item.technical_judges)) || [];
      this.$bvModal.show("assign_tech_judges_modal");
    },
    resetAssignJudgesModal() {
      this.itemPreviewed = null;
      this.selectedNonTechJudges = [];
      this.selectedTechJudges = [];
    },
    addNonTechJudgeToAward(user) {
      if (!user) return;

      if (this.selectedNonTechJudges.length === 0) {
        this.selectedNonTechJudges.push(user);
        return;
      }

      if (
        this.selectedNonTechJudges.find((judge) => judge.uuid === user.uuid)
      ) {
        this.selectedNonTechJudges = this.selectedNonTechJudges.filter(
          (judge) => judge.uuid !== user.uuid
        );
        return;
      }

      this.selectedNonTechJudges.push(user);
    },
    addTechJudgeToAward(user) {
      if (!user) return;

      if (this.selectedTechJudges.length === 0) {
        this.selectedTechJudges.push(user);
        return;
      }
      if (this.selectedTechJudges.find((judge) => judge.uuid === user.uuid)) {
        this.selectedTechJudges = this.selectedTechJudges.filter(
          (judge) => judge.uuid !== user.uuid
        );
        return;
      }

      this.selectedTechJudges.push(user);
    },
    fetchAllKpis() {
      this.$store.dispatch("kpi/fetchAllData");
      if (this.itemPreviewed) {
        this.selectedKpis = this.itemPreviewed.kpis || [];
      }
    },
    submitNonTechJudgesHandler() {
      if (!this.itemPreviewed) return;
      const judges = this.selectedNonTechJudges?.map(({ uuid }) => uuid);
      this.$store
        .dispatch("awards/assignNonTechJudges", {
          award: this.itemPreviewed.uuid,
          judges,
        })
        .then(() => {
          this.$bvModal.hide("assign_non_tech_judges_modal");
          this.itemPreviewed = null;
          this.selectedNonTechJudges = [];
        });
    },
    getTechJudgesInfo() {
      const judges = this.selectedTechJudges?.map(({ uuid, department }) => ({
        uuid,
        department: department?.value || department?.uuid || department || "",
      }));

      return judges;
    },
    submitTechJudgesHandler() {
      if (!this.itemPreviewed) return;

      const judges = this.getTechJudgesInfo();

      this.$store
        .dispatch("awards/assignTechJudges", {
          award: this.itemPreviewed.uuid,
          judges,
        })
        .then(() => {
          this.$bvModal.hide("assign_tech_judges_modal");
          this.itemPreviewed = null;
          this.selectedTechJudges = [];
        });
    },
    submitKpisHandler() {
      if (!this.itemPreviewed) return;

      const kpis = this.selectedKpis?.map((kpi) => ({
        kpi: kpi.uuid,
        max_score: kpi["max_score"],
      }));

      this.$store
        .dispatch("awards/assignKpis", {
          award: this.itemPreviewed.uuid,
          kpis,
        })
        .then(() => {
          this.$bvModal.hide("assign_kpis_modal");
          this.itemPreviewed = null;
          this.selectedKpis = [];
        });
    },
    goToEditHandler(uuid) {
      this.$router.push({
        name: "edit-award",
        query: { uuid },
      });
    },
    formatDate(date) {
      return new Date(date).toLocaleString();
    },
    cancelDeletionHandler() {
      this.$bvModal.hide("delete-warning-modal");
    },
    deleteHandler(id, payload) {
      this.$bvModal.hide(id);
      payload && payload.length
        ? this.deleteAllSelectedHandler(payload)
        : this.deleteAction({ uuid: payload.uuid });
    },
    deleteAction(payload) {
      this.$store.dispatch("awards/deleteData", payload);
    },
    deletionWarningHandler(items) {
      if (!items) return;
      this.itemsToBeDeleted = items;
      this.$bvModal.show("delete-warning-modal");
    },
    deleteAllSelectedHandler() {
      if (!this.selectedRows || !this.selectedRows.length) return;
      this.$store
        .dispatch("awards/deleteAllSelectedRows", {
          awards: this.selectedRows,
        })
        .then(() => {
          this.selectedRows = [];
        });
    },
    unselectAllRowsHandler() {
      this.selectedRows = [];
    },
    rowSelectedHandler(state, uuid) {
      if (
        this.selectedRows &&
        this.selectedRows.length &&
        this.selectedRows.find((e) => e === uuid)
      ) {
        this.selectedRows = this.selectedRows.filter((e) => e !== uuid);
      } else {
        this.selectedRows.push(uuid);
      }
    },
    changePaginationHandler(page = 1) {
      this.fetchAwardsHandler({ page });
    },
    searchDocsHandler(searchText) {
      clearTimeout(this.searchQueryTimer);
      this.searchQueryTimer = setTimeout(() => {
        const searchQuery = { name: searchText };
        this.fetchAwardsHandler(searchQuery);
      }, 300);
    },
    openItemForm(e, item = null) {
      if (item) this.singleItem = item;
      if (
        item &&
        Object.keys(item).includes("can_edit_or_delete") &&
        item.can_edit_or_delete == 0
      )
        return;

      this.$bvModal.show("crud-form");
    },
    hideItemForm() {
      this.singleItem = null;
      this.$store.dispatch("awards/resetModule");
    },

    fetchAwardsHandler(params = null) {
      this.appliedFilters = {
        ...this.appliedFilters,
        page: 1, // reset page to 1 unless page param is passed underneath
        ...params,
      };
      this.$store.dispatch(
        "awards/fetchData",
        JSON.parse(JSON.stringify(this.appliedFilters))
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.mi-end-2 {
  margin-inline-end: 1rem;
}
</style>
