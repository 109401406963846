<template>
  <serverResponseCheck :id="id" :payload="userData">
    <b-card v-if="userData" class="mb-0">
      <b-row>
        <b-col
          cols="12"
          xl="12"
          class="d-flex justify-content-between flex-column"
        >
          <div class="d-flex justify-content-start align-items-center">
            <b-avatar
              :src="userData.image ? userData.image.path || '' : ''"
              :text="avatarText(userData.name)"
              :variant="`light-${resolveUserRoleVariant(userData.role)}`"
              size="104px"
              rounded
            />
            <div class="d-flex flex-column ml-1">
              <h4 class="mb-50 d-flex">
                <b-link
                  class="font-weight-bold d-block text-nowrap"
                  :class="userType ? 'text-link' : 'text-secondary'"
                  :to="userType ? `/${userType}/${userData.uuid}` : '#'"
                  :target="userType ? '_blank' : ''"
                >
                  <feather-icon
                    v-if="userType"
                    icon="ExternalLinkIcon"
                    size="12"
                  />
                  {{ userData.name }}
                </b-link>
                <b-badge class="mx-50"
                  >{{ $t("id") || "ID" }}: #{{ userData.id }}
                </b-badge>
              </h4>
              <p class="card-text mb-05">
                {{ userData.email }}
              </p>
              <p class="card-text mb-0">
                {{ userData.mobile }}
              </p>
            </div>
          </div>
        </b-col>

        <b-col cols="12" xl="12">
          <table class="mt-2 mt-xl-0 w-100">
            <tr v-if="userData.roles && userData.roles.length">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("roles") || "Roles" }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{
                  userData.roles
                    .map((role) => {
                      return role.name;
                    })
                    .join(", ")
                }}
              </td>
            </tr>
            <tr v-if="userData.actors && userData.actors.length">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("actors") || "Actors" }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{
                  userData.actors
                    .map((actor) => {
                      return actor.name;
                    })
                    .join(", ")
                }}
              </td>
            </tr>
            <tr v-if="userData.address">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("address") || "Address" }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                <span>
                  {{ userData.address }}
                  <br />
                </span>
                {{
                  userData.country
                    ? isRTL
                      ? userData.country.name_ar
                      : userData.country.name
                    : "-"
                }},
                {{
                  userData.governorate
                    ? isRTL
                      ? userData.governorate.name_ar
                      : userData.governorate.name
                    : "-"
                }},
                {{
                  userData.area
                    ? isRTL
                      ? userData.area.name_ar
                      : userData.area.name
                    : "-"
                }}
              </td>
            </tr>
            <tr v-if="userData.gender">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("gender") || "Gender" }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ $t(userData.gender) || userData.gender }}
              </td>
            </tr>
            <tr v-if="userData.family_root">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("family_root") || "family_root" }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ $t(userData.family_root) || userData.family_root }}
              </td>
            </tr>
            <tr v-if="Object.keys(userData).includes('file_number')">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("file_number") || "file_number" }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ userData.file_number }}
              </td>
            </tr>
            <tr v-if="userData.job_title">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("job_title") || "job_title" }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{
                  isRTL ? userData.job_title.name_ar : userData.job_title.name
                }}
              </td>
            </tr>
            <tr v-if="userData.marital_status">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("marital_status") || "marital_status" }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ $t(userData.marital_status) || userData.marital_status }}
              </td>
            </tr>
            <tr
              v-if="
                Object.keys(userData).includes('number_of_children') &&
                userData.number_of_children
              "
            >
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("number_of_children") || "number_of_children" }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ userData.number_of_children }}
              </td>
            </tr>
            <tr v-if="userData.mobile_number">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("mobile_number") || "mobile_number" }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ userData.mobile_number }}
              </td>
            </tr>
            <tr v-if="userData.land_line_number">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("land_line_number") || "land_line_number" }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ userData.land_line_number }}
              </td>
            </tr>
            <tr v-if="userData.national_id">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("national_id") || "national_id" }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ userData.national_id }}
              </td>
            </tr>
            <tr v-if="userData.passport_id">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("passport_id") || "passport_id" }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ userData.passport_id }}
              </td>
            </tr>
            <tr v-if="userData.birth_date">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("birth_date") || "birth_date" }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ new Date(userData.birth_date).toLocaleDateString() }}
              </td>
            </tr>

            <tr v-if="userData.created_at">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("created_at") || "created at" }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ new Date(userData.created_at).toLocaleDateString() }}
              </td>
            </tr>
            <tr v-if="Object.keys(userData).includes('files_delivered')">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("files_delivered") || "files_delivered" }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ userData.files_delivered ? $t("yes") : $t("no") }}
              </td>
            </tr>
            <tr v-if="Object.keys(userData).includes('have_family_tree')">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("have_family_tree") || "have_family_tree" }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ userData.have_family_tree ? $t("yes") : $t("no") }}
              </td>
            </tr>
            <tr v-if="Object.keys(userData).includes('last_subscription_year')">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("last_subscription_year") || "last_subscription_year" }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ userData.last_subscription_year }}
              </td>
            </tr>
            <tr v-if="userData.notes">
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("notes") || "notes" }}
                </span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ userData.notes }}
              </td>
            </tr>

            <tr v-if="userData.relative_name" class="border-top w-100">
              <th class="py-1">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("relative_info") || "Relative Info" }}
                </span>
              </th>
              <td v-if="userData.relative_name" class="py-1 text-capitalize">
                {{ `${$t("relative_name")}: ${userData.relative_name}` }}
                <br />{{
                  `${$t("relative_national_id")}: ${
                    userData.relative_national_id
                  }`
                }}
                <br />{{
                  `${$t("relative_registration_number")}: ${
                    userData.relative_registration_number
                  }`
                }}
                <br />
                {{
                  userData.relative_passport_id
                    ? `${$t("relative_passport_id")}: ${
                        userData.relative_passport_id
                      }`
                    : ""
                }}
                {{ $t("relative_certificate") || "relative_certificate" }}:
                <b-link
                  :href="userData.relative_certificate"
                  target="_blank"
                  class="font-weight-bold text-nowrap my-0"
                >
                  {{ $t("link") || "link" }}
                </b-link>
              </td>
            </tr>
            <tr v-if="userData.created_by" class="border-top w-100">
              <th class="py-1">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">
                  {{ $t("created_by") || "created at" }}
                </span>
              </th>
              <td class="py-1 text-capitalize">
                <div class="d-flex mb-0">
                  {{ `${$t("name")}:` }}
                  <b-link
                    :to="`/user/${userData.created_by.uuid}`"
                    target="_blank"
                    class="
                      font-weight-bold
                      text-nowrap
                      d-flex
                      align-items-center
                    "
                  >
                    <span class="mx-50">
                      {{ ` ${userData.created_by.name}` }}
                    </span>
                    <feather-icon icon="ExternalLinkIcon" size="12" />
                  </b-link>
                </div>
                {{ `${$t("mobile")}: ${userData.created_by.mobile}` }}
                <br />
                {{ `${$t("email")}: ${userData.created_by.email}` }}
              </td>
            </tr>
          </table>
        </b-col>
      </b-row>
    </b-card>
  </serverResponseCheck>
</template>

<script>
import {
  BCard,
  BButton,
  BAvatar,
  BRow,
  BCol,
  BBadge,
  BLink,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import useUsersList from "./useUsersList";
import serverResponseCheck from "@/components/hoc/serverResponseCheck.vue";

export default {
  components: {
    BCard,
    BButton,
    BLink,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    serverResponseCheck,
  },
  props: {
    userData: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    id: {
      type: String,
      required: true,
    },
    userType: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    isRTL() {
      return window.localStorage.getItem("language") === "ar";
    },
    statusVariant() {
      return (status) =>
        ({
          "on progress": "warning",
          interview: "warning",
          registered: "primary",
          paid: "primary",
          approved: "success",
          pending: "secondary",
          rejected: "danger",
          refused: "danger",
          denied: "danger",
        }[status] || "secondary");
    },
  },
  methods: {},
  setup() {
    const { resolveUserRoleVariant } = useUsersList();
    return {
      avatarText,
      resolveUserRoleVariant,
    };
  },
};
</script>
