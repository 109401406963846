<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        {{ $t("filters") || "Filters" }}
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <!-- <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <AppSelect
            id="tags-select"
            v-model="categoryFilter"
            :options="tags"
            class="w-100"
            :placeholder="$t('filter_by_tags') || 'filter by Tag'"
            :field-label="$t('tag') || 'Tag'"
            :label="$t('filter_by_tags') || 'filter by Tag'"
            search-store-action="tags/fetchAllData"
            @input="filterHandler"
          />
        </b-col> -->
        <b-col cols="12" md="4" class="mb-md-0 my-2">
          <label>
            {{ $t("created_at") || "Created At" }}
          </label>
          <flat-pickr
            v-model="dateFilter"
            class="form-control"
            :dir="isRTL ? 'rtl' : 'ltr'"
            :placeholder="$t('no_date_selected') || 'No date Selected'"
            :config="{ mode: 'range', locale }"
            @on-change="createdAtFilterHandler"
          />
        </b-col>
        <!-- <b-col cols="12" md="4" class="mb-md-0 my-2">
          <label>
            {{ $t("private_public") || "Private/Public" }}
          </label>
          <b-form-checkbox
            id="share_only"
            v-model="share_only"
            name="share_only"
            value="1"
            class="mt-1"
            @change="filterHandler"
          >
            {{ $t("is_public") || "Is Public" }}
          </b-form-checkbox>
        </b-col> -->
      </b-row>
      <transition name="slide">
        <b-row v-if="created_at">
          <b-col
            cols="12"
            class="mb-md-0 my-2 d-flex align-items-center justify-content-end"
          >
            <AppButton
              variant="danger"
              class=""
              @click="resetAllFiltersHandler"
            >
              {{ $t("Reset_filters")  }}
            </AppButton>
          </b-col>
        </b-row>
      </transition>
    </b-card-body>
  </b-card>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import { Arabic } from "flatpickr/dist/l10n/ar.js";

import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormCheckbox,
  BFormDatepicker,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { AppSelect } from "@/components/form/index";
// import userCan from "@/mixins/UserCan";
import { AppButton } from "@/components/form";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormDatepicker,
    vSelect,
    BFormCheckbox,
    flatPickr,
    AppButton,
    Arabic,
    AppSelect,
  },
  data() {
    return {
      dateFilter: null,
      created_at: null,
    };
  },
  computed: {
    isLoading() {
      return false;
    },
    isRTL() {
      return window.localStorage.getItem("language") === "ar";
    },
    locale() {
      return this.isRTL ? Arabic : "en";
    },
  },
  watch: {},
  created() {},
  methods: {
    // userCan,
    resetAllFiltersHandler() {
      this.dateFilter = null;
      this.filterHandler();
    },
    createdAtFilterHandler(val) {
      this.created_at = this.dateFilter
        ? this.isRTL
          ? this.dateFilter?.split(" - ")
          : this.dateFilter?.split(" to ")
        : null;
      this.filterHandler();
    },
    filterHandler() {
      // all filters
      const filters = {
        created_at: this.created_at ? this.created_at : undefined,
      };

      this.$emit("filterDocs", filters);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
